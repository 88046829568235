import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'

const MuzeumWsiStojadlaPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Muzeum Ziemi Mińskiej - 'chwytliwy tytuł'" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Muzeum Ziemi Mińskiej - 'chwytliwy tytuł'</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>Odwiedzając obiekt można obejrzeć stałe ekspozycje prezentujące:</p>
        <ul>
          <li>
            Tabla, dyplomy, zdjęcia dotyczące dziejów, funkcjonującej od 1881
            roku, Ochotniczej Straży Pożarnej w Mińsku Mazowieckim. Eksponaty
            zostały pozyskane dzięki uchwale OSP Mińsk Mazowiecki dotyczącej
            zakończenia depozytu pamiątkowych zbiorów jednostki przekazanych
            czasowo do Muzeum Pożarnictwa w Kotuniu.
          </li>
          <li>
            „Jak żyli nauczyciele w II RP” – ta unikatowa wystawa została
            zaaranżowana w oryginalnym wnętrzu mieszkania małżeństwa nauczycieli
            Janiny i Józefa Sylwestrowiczów. Od 1950 roku mieszkali oni w
            budynku stanowiącym obecnie siedzibę MZM. Staraniem placówki oraz
            związanej rodzinnie z pedagogami prof. dr hab. Marii Ekiel –
            Jeżewskiej odtworzono wygląd użytkowanych przez nich pomieszczeń.
            Ekspozycja ta zdobyła wyróżnienie w prestiżowym konkursie SYBILLA
            2016.
          </li>
          <li>
            Zakład zegarmistrzowsko – optyczno – jubilerski Lucjana i Władysława
            Dąbrowskich. Ekspozycja ta odtwarza wnętrze jednego z najbardziej
            znanych punktów usługowych w Mińsku Mazowieckim. Wyposażenie
            zakładu, który istniał od 1939 roku zostało przekazane MZM przez
            córkę Lucjana Dąbrowskiego. Wystawie towarzyszy prezentacja
            spuścizny po zegarmistrzu Edwardzie Kwiatkowskim.
          </li>
          <li>
            Rzeźby dłuta amerykańskiego twórcy Louisa Długosza (1915 – 2002).
            Wśród dwudziestu jeden przedmiotów można obejrzeć artystyczne
            wizerunki takich osób jak m.in. św. Tomasz z Akwinu, św. Maksymilian
            Maria Kolbe, władca Polski król Kazimierz Wielki oraz grecki filozof
            Arystoteles. Co ciekawe rzeźbiarz posiadał polskie pochodzenie. Ze
            swoją drugą żoną, Jadwigą, zamieszkał w jej rodzinnym Mińsku
            Mazowieckim.
          </li>
        </ul>
        <p>
          Muzeum Ziemi Mińskiej w Mińsku Mazowieckim jest także organizatorem
          różnych tematycznie wystaw czasowych. Prezentują one przede wszystkim
          artystyczny lub kolekcjonerski dorobek mieszkańców ziemi mińskiej.
          Część z nich poświęcona jest dokonaniom osób pochodzących z
          pozostałych regionów Polski, Europy, a nawet innych części świata.
          Placówka, kierowana od momentu jej utworzenia przez dyrektora Leszka
          Celeja, wciela się ponadto w rolę inicjatora lub współinicjatora
          wydarzeń realizowanych w powiecie mińskim, województwie mazowieckim,
          innych rejonach naszego państwa lub także poza jego granicami.
          Przedsięwzięcia te posiadają niejednolity wymiar. Wśród nich można
          wymienić m.in. rekonstrukcje historyczne, sesje popularnonaukowe,
          koncerty, obchody rocznic ważnych dla Polek i Polaków, odnawianie
          zabytkowych świadectw przeszłości, a także jarmarki kupieckie oraz
          rajdy motocyklowe i wyścigi kolarskie.
        </p>
        <p>
          Do najważniejszych wydarzeń przygotowanych przez MZM należy zaliczyć
          m.in. Dzień Himilsbacha – DeHa, obchody święta 7 Pułku Ułanów
          Lubelskich, obchody roku Fryderyka Chopina (2010) i Czesława Miłosza
          (2011), Targi „Stragan Sendomierski” i „Kupieckie Mensko” oraz
          Memoriał Feliksa Rawskiego. Od samego początku istotne miejsce w
          funkcjonowaniu MZM zajmuje działalność wydawnicza. W związku z tym
          światło dzienne ujrzały takie tytuły jak np. „Siódmacy w kampanii
          wrześniowej”, „Mińskie impresje architektoniczne Marka Chabrowskiego”,
          „Rok 1920 na Ziemi Mińskiej”, „Mińsk Mazowiecki i okolice w gazetach
          polskich z lat 1831 – 1939 ze zbiorów Muzeum Ziemi Mińskiej” czy
          „Życie polityczne społeczno – gospodarcze i kulturalne powiatu
          mińskomazowieckiego w latach 1918 – 1939”. Cykliczną pozycją
          wydawniczą placówki są „Mińskie Zeszyty Muzealne”. Z regularną
          częstotliwością publikowane są także książki z serii „W służbie miasta
          i społeczeństwa”. Co więcej z okazji obchodów 600 – lecia nadania praw
          miejskich Mińskowi Mazowieckiemu MZM zainicjowało wydanie kilkutomowej
          historii miasta. Zostały one opatrzone wspólnym tytułem „VI wieków
          Mińska Mazowieckiego”.
        </p>
        <p>
          Muzeum Ziemi Mińskiej skupia wokół siebie lokalnych twórców i badaczy
          dziejów. Wspólnie z nimi zainicjowało ono akcję oznaczania ważnych dla
          historii miasta budynków tablicami informacyjnymi. Pojawiły się one na
          takich obiektach jak m.in. poczta przy ulicy Warszawskiej, siedzibie
          powiatowego oddziału Związku Nauczycielstwa Polskiego, kościele p.w.
          Narodzenia Najświętszej Maryi Panny i Szkole Podstawowej nr 1 im.
          Mikołaja Kopernika w Mińsku Mazowieckim. Staraniem MZM przy niektórych
          mińskomazowieckich rondach pojawiły się tablice informujące o
          przebiegu życia ich patronów.
        </p>
        <p>
          Jak już wspomniano główna siedziba MZM mieści się w budynku określanym
          mianem willi lub pałacyku Łubieńskiej. Obiekt ten został wzniesiony
          pod koniec lat trzydziestych XX wieku. Projektantem miejsca
          zamieszkania Marii Magdaleny hr. Łubieńskiej (1901 – 1980) został
          architekt, katyńczyk, Michał Sławiński (1883 – 1940). W drugiej
          połowie lat czterdziestych ubiegłego stulecia willę wyremontowano po
          zniszczeniach wojennych i nadano tym samym jej obecny kształt. Warto
          zauważyć, że pierwotnie jej piętrowa część znajdowała się tylko
          pośrodku budynku. Skrzydła boczne były natomiast parterowe.
          Dobudowanie piętra nastąpiło zgodnie z pozwoleniem wydanym w
          listopadzie 1947 roku przez Zarząd Miejski Miasta Mińsk Mazowiecki. Co
          ciekawe właścicielka budynku nigdy na stałe w nim nie zamieszkała. W
          okresie drugiej wojny światowej służył on okupantom niemieckim, którzy
          w lipcu 1944 roku dokonali jego podpalenia. W czasie odbudowy budynku
          podjętej w 1947 roku przez Marię Magdalenę Łubieńska zamieszkała ona w
          pobliskich, istniejących do dziś, pomieszczeniach gospodarczych. Po
          przeprowadzeniu prac nie pozwolono jej na użytkowanie obiektu
          przeznaczając go na mieszkania kwaterunkowe. Wobec tego właścicielka
          musiała pogodzić się z dalszym przebywaniem we wspomnianym miejscu.
          Pałacyk tymczasem przeszedł na własność skarbu państwa, zaś na
          przełomie lat 80 – tych i 90 – tych XX wieku stał się własnością
          komunalną.
        </p>
        <p>
          W 2000 roku stał się on siedzibą Towarzystwa Przyjaciół Mińska
          Mazowieckiego. Pięć lat później przekazano go nowo powołanemu Muzeum
          Ziemi Mińskiej w Mińsku Mazowieckim. Poza główną siedzibą znajdującą
          się przy ulicy Stefana Okrzei 16 posiada ono także Dział 7 Pułku
          Ułanów Lubelskich, który mieści się w willi dr Jana Huberta przy ulicy
          Generała Kazimierza Sosnkowskiego. Informacje dotyczące bieżącej
          działalności MZM można znaleźć na stronie internetowej muzeum –
          <a href="www.mzm-minskmaz.pl" target="_blank" rel="nofollow">
            www.mzm-minskmaz.pl
          </a>
          .
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/mzmokrzei/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/minsk-mazowiecki/mzmokrzei/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MuzeumWsiStojadlaPage
